import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { navigate } from 'gatsby'

import storeConfig from '../../store.config'

const isBrowser = typeof window !== `undefined`

function Page() {
  if (!isBrowser) {
    return null
  }

  if (document.cookie.indexOf('memberAuthToken') < 0) {
    window.location.href = storeConfig.loginUrl

    return null
  }

  navigate('/')

  return (
    <>
      <GatsbySeo />

      <div>loading...</div>
    </>
  )
}

export default Page
